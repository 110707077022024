import Image from "next/image";

const ReviewCard = ({ img, name, country, review }) => {
  return (
  
    <div className="flex items-center justify-center p-3">
      <div
        className="grid grid-cols-1 space-y-4 bg-white rounded-[10px] p-5 w-[320px] md:w-[392px] min-h-[18rem]"
        style={{ boxShadow: "0px 4px 25px rgba(0, 0, 0, 0.12)" }}
      >
        <div className="flex space-x-2 items-center">
          {/* <div className="inline rounded-full w-[62px] h-[62px]"> */}
          <Image
            src={img ? img : "/images/avatar.webp"}
            width={62}
            height={62}
            className="rounded-full w-[62px] h-[62px]"
            alt="avatar"
          />
          {/* </div> */}
          <div className="flex flex-col space-y-1">
            <p className="font-medium fontNew text-xl">{name}</p>
            <span className="font-normal fontNew text-sm">{country}</span>
          </div>
        </div>
        <div className="flex items-start">
          <span className="font-normal fontNew text-base line-clamp-5">
            {review}
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <Image src={"/images/star.svg"} width={24} height={24} alt="star 1" />
          <Image src={"/images/star.svg"} width={24} height={24} alt="star 2" />
          <Image src={"/images/star.svg"} width={24} height={24} alt="star 3" />
          <Image src={"/images/star.svg"} width={24} height={24} alt="star 4" />
          <Image src={"/images/star.svg"} width={24} height={24} alt="star 5" />
        </div>
      </div>
    </div>
    
  );
};

export default ReviewCard;
